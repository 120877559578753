import json from "../../assets/data.json";
import Page from "./page";

export default function AusgabeHertzschlag(params) {
  const ret = document.createElement("div");

  const ausg = json.hertzschlag.find((i) => i["Ausgabe"] == params.id);

  if (ausg === undefined) {
    throw new Error(params.id + " not found");
  }

  let pagehtml = `
  <img class="cover" src="${json.baseUrl}hertzschlag/images/${ausg["Ausgabe"]}.jpg"></img>
  <h2>HertzSCHLAG ${ausg["Ausgabe"]}</h2>
  <h1>${ausg["Thema"]}</h1>
  `;
  for (let i in ausg) {
    if (
      i != "yumpu" &&
      i != "Thema" &&
      i != "Cover" &&
      i != "Ausgabe" &&
      ausg[i] != null
    ) {
      pagehtml = pagehtml + "<p><b>" + i + ":</b> " + ausg[i] + "</p>";
    }
  }

  pagehtml += `
<a class="button" href="#hertzschlag/${ausg["Ausgabe"]}/pdf">
  <i class="fa fa-file-pdf-o"></i>PDF Anzeigen
</a>
<button>
  <i class="fa fa-download"></i>Download
  <div class="dropdown">
    <a href="${json.downloadUrl || json.baseUrl}hertzschlag/pdf/compressed/${
    ausg["Ausgabe"]
  }.pdf">
      <i class="fa fa-file"></i>Niedrige Qualität
    </a>
    <a href="${json.downloadUrl || json.baseUrl}hertzschlag/pdf/${
    ausg["Ausgabe"]
  }.pdf">
      <i class="fa fa-file"></i>Hohe Qualität
    </a>
  </div>
</button>
`;

  ret.innerHTML = pagehtml;

  return Page(ret);
}
