export default function HeaderHertzschlag() {
  const el = document.createElement("div");

  el.innerHTML = `
  <a href="#hertzblatt">
    <h1>hertz heute / HertzBLATT Archiv</h1>
  </a>
  <input type="checkbox" id="nav-toggle" name="nav-toggle" />
  <nav>
    <a href="#" style="margin-left: 25px">HertzSCHLAG Archiv</a>
    <a href="#about" style="margin-left: 25px">Impressum</a>
    <label for="nav-toggle">✕</label>
  </nav>
  <label for="nav-toggle">☰</label>
  `;

  return el;
}
