export default function HeaderHertzschlag() {
  const el = document.createElement("div");

  el.innerHTML = `
  <a href="#">
    <h1>HertzSCHLAG Archiv</h1>
  </a>
  <input type="checkbox" id="nav-toggle" name="nav-toggle" />
  <nav>
    <a href="#hertzblatt" style="margin-left: 25px">HertzBLATT Archiv</a>
    <a href="#hertzschlag/liste" style="margin-left: 25px">Alle Ausgaben</a>
    <a href="#about" style="margin-left: 25px">Impressum</a>
    <label for="nav-toggle">✕</label>
  </nav>
  <label for="nav-toggle">☰</label>
  `;

  return el;
}
